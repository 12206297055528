import { createGraphiQLFetcher } from '@graphiql/toolkit'
import React, { useState, useMemo } from 'react'
import { createClient } from 'graphql-ws'
import { GraphiQL } from 'graphiql'
import ReactDOM from 'react-dom'

import { Agent, AgentContext } from './Agent.jsx'
import { Share } from './Share.jsx'
import { Logo } from './Logo.jsx'

import 'graphiql/graphiql.css'
import './main.css'

const url = new URL(window.location.toString())

let headers = {}
try {
  headers = JSON.parse(localStorage.getItem('aa:headers') || '{}')
} catch (err) {}

const query = url.searchParams.get('query')
const variables = url.searchParams.get('variables')
const init = {}
if (query) init.query = query
if (variables) init.variables = JSON.parse(variables)

// Override the session id on every page load
headers.session = crypto.randomUUID()
localStorage.setItem('aa:headers', JSON.stringify(headers))

const fetcher = createGraphiQLFetcher({
  url: url.href.replace(/\?.+/, ''),
  wsClient: createClient({
    url: url.href.replace(/^http/, 'ws').replace(/\?.+/, ''),
    async connectionParams() {
      const params = { ...headers }
      const stored = localStorage.getItem('aa:headers')
      if (stored) Object.assign(params, JSON.parse(stored))
      return params
    }
  })
})

ReactDOM.render(<App />, document.getElementById('app'))

function App() {
  const [agentApiKey, setAgentApiKey] = useState(function () {
    const authorization = new Headers(headers).get('authorization')
    const match = authorization?.match(/Bearer (.+)/)
    if (!match) return null
    return match[1]
  })

  const AuthPlugin = useMemo(
    () => ({
      icon: Icon,
      title: 'Authorization',
      content: Agent.bind(null, { onChange: setAgentApiKey })
    }),
    []
  )

  return (
    <AgentContext.Provider value={agentApiKey}>
      <GraphiQL
        {...init}
        shouldPersistHeaders
        fetcher={fetcher}
        headers={
          agentApiKey &&
          new Headers(headers).get('authorization') !== `Bearer ${agentApiKey}`
            ? JSON.stringify(
                {
                  ...headers,
                  Authorization: agentApiKey
                    ? `Bearer ${agentApiKey}`
                    : undefined
                },
                null,
                2
              )
            : undefined
        }
        defaultHeaders={JSON.stringify(
          {
            ...headers,
            Authorization: agentApiKey ? `Bearer ${agentApiKey}` : undefined
          },
          null,
          2
        )}
        onEditHeaders={(value) => {
          try {
            if (JSON.parse(value)) {
              window.localStorage.setItem('aa:headers', value)
            }
          } catch (err) {}
        }}
        plugins={[AuthPlugin]}
        toolbar={{
          additionalContent: <Share execute={Boolean(query)} />
        }}
      >
        <GraphiQL.Logo>
          <Logo />
        </GraphiQL.Logo>
      </GraphiQL>
    </AgentContext.Provider>
  )
}

function Icon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
      <g fill='currentColor'>
        <rect fill='none' height='24' width='24' />
        <path d='M21,10h-8.35C11.83,7.67,9.61,6,7,6c-3.31,0-6,2.69-6,6s2.69,6,6,6c2.61,0,4.83-1.67,5.65-4H13l2,2l2-2l2,2l4-4.04L21,10z M7,15c-1.65,0-3-1.35-3-3c0-1.65,1.35-3,3-3s3,1.35,3,3C10,13.65,8.65,15,7,15z' />
      </g>
    </svg>
  )
}
