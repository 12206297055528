import React, { useState, useEffect, useContext, createContext } from 'react'
import { createClient } from '@prismicio/client'

import style from './auth.module.css'

export const AgentContext = createContext(null)

const client = createClient('allaboard', { fetch })

export function Agent({ onChange }) {
  const [agentApiKey, setAgentApiKey] = useState(useContext(AgentContext))
  const [error, setError] = useState(null)
  const [name, setName] = useState(null)

  useEffect(
    function () {
      if (!agentApiKey) return

      fetch(new URL('/api', import.meta.env.AA_INTERNAL_ORIGIN), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'api-key': agentApiKey
        },
        body: JSON.stringify({
          query: `
              query GetAgent {
                agent: getSalesAgent {
                  uid
                }
              }
            `,
          variables: { agentApiKey }
        })
      })
        .then(async function (res) {
          const { data, errors } = await res.json()
          if (errors) throw new AggregateError(errors, 'Failed to authenticate')

          setError(null)
          onChange(agentApiKey)

          /** @type {import('@prismicio/client').PrismicDocument<{ name: import('@prismicio/client').KeyTextField }>} */
          const doc = await client.getByUID('agent', data.agent.uid)
          if (!doc) return
          setError(null)
          setName(doc.data.name)
        })
        .catch(function (err) {
          if (err instanceof Error) setError(err)
          else console.error(err)
        })
    },
    [agentApiKey]
  )

  const onSubmit = async (event) => {
    const { target } = event
    if (!target.checkValidity()) {
      target.reportValidity()
    } else {
      const data = new FormData(target)
      const apiKey = /** @type {string?} */ (data.get('agent-api-key'))
      if (apiKey) setAgentApiKey(apiKey)
      event.preventDefault()
    }
  }

  const onReset = () => {
    setAgentApiKey(null)
    onChange(null)
    setError(null)
  }

  return (
    <section>
      <div className={style.header}>Authorization</div>
      <div className={style.content}>
        <p>
          {agentApiKey
            ? `You are signed in${name ? ` as ${name}` : ''}.`
            : 'Input your account credentials to generate authorization headers.'}
        </p>
      </div>
      {error && (
        <div className={style.error}>
          {error.errors?.map((err) => err.message).join(', ') ?? error.message}
        </div>
      )}
      <form onSubmit={onSubmit} onReset={onReset}>
        {agentApiKey ? (
          <>
            <button type='reset' className={style.button}>
              Sign out
            </button>
          </>
        ) : (
          <>
            <label className={style.label}>
              API Key
              <input
                type='text'
                name='agent-api-key'
                autoComplete='off'
                className={style.input}
                required
              />
            </label>
            <button className={style.button}>Submit</button>
          </>
        )}
      </form>
    </section>
  )
}
