import React, { useEffect, useState } from 'react'
import {
  ToolbarButton,
  useEditorContext,
  useExecutionContext
} from '@graphiql/react'

const KEYS = 'Shift-Ctrl-S'

export function Share({ execute }) {
  const [color, setColor] = useState(null)
  const execution = useExecutionContext({ nonNull: true, caller: Share })
  const { queryEditor, variableEditor, responseEditor } = useEditorContext({
    nonNull: true,
    caller: Share
  })

  useEffect(
    function () {
      if (execute && responseEditor && variableEditor && queryEditor) {
        execution.run()
      }
    },
    [queryEditor, variableEditor, responseEditor]
  )

  for (const editor of [queryEditor, variableEditor]) {
    useEffect(() => editor?.addKeyMap({ [KEYS]: copy }), [editor])
  }

  useEffect(
    function () {
      if (!color) return
      setTimeout(setColor, 2000, null)
    },
    [color]
  )

  function copy() {
    const query = queryEditor?.getValue()
    const variables = variableEditor?.getValue()

    const url = new URL(window.location.toString())
    if (query) url.searchParams.set('query', query)
    if (variables) url.searchParams.set('variables', JSON.stringify(variables))

    navigator.clipboard.writeText(url.toString()).then(
      () => setColor('success'),
      () => setColor('error')
    )
  }

  return (
    <ToolbarButton onClick={() => copy()} label={`Share query (${KEYS})`}>
      <svg
        aria-hidden='true'
        className='graphiql-toolbar-icon'
        style={color ? { color: `var(--color-${color})` } : {}}
        height='48'
        viewBox='0 96 960 960'
        width='48'
      >
        <path
          fill='currentColor'
          d='M220 1016q-24 0-42-18t-18-42V447q0-24 18-42t42-18h169v60H220v509h520V447H569v-60h171q24 0 42 18t18 42v509q0 24-18 42t-42 18H220Zm229-307V252l-88 88-43-43 161-161 161 161-43 43-88-88v457h-60Z'
        />
      </svg>
    </ToolbarButton>
  )
}
