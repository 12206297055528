import React from 'react'

import style from './logo.module.css'

export function Logo() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 64 56'
      fill='none'
      className={style.logo}
    >
      <path
        fill='#23015A'
        fill-rule='evenodd'
        d='M15.6 5.6c1-2.8 4-4.4 6.8-3.4h.1c2.8 1 4.4 4 3.5 6.8L12.7 50.2c-1 2.9-4 4.4-6.8 3.5-2.9-1-4.4-4-3.5-6.9L15.6 5.6ZM12 28c0 1.2 1 2.2 2.2 2.2 1.3 0 2.2-1 2.2-2.2 0-1.3-1-2.3-2.2-2.3-1.2 0-2.2 1-2.2 2.3Zm7-20.8c0 1.1.9 2 2 2 1 0 2-.9 2-2 0-1-1-2-2-2-1.1 0-2 1-2 2ZM5.4 48.7c0 1 .8 2 2 2 1 0 2-.9 2-2 0-1-1-2-2-2-1.2 0-2 .9-2 2Z'
        clip-rule='evenodd'
      />
      <mask
        id='a'
        width='46'
        height='16'
        x='8'
        y='18'
        maskUnits='userSpaceOnUse'
        className={style.alpha}
      >
        <path
          fill='#fff'
          d='m13.6 22.6 33.8-3.8a5.4 5.4 0 0 1 1.2 10.8l-33.9 3.8a5.4 5.4 0 0 1-1.2-10.8Zm33 1.3c0 1.2 1 2.2 2.1 2.2 1.3 0 2.3-1 2.3-2.3 0-1.2-1-2.2-2.3-2.2-1.2 0-2.2 1-2.2 2.3Zm-17 2.2c0 1 1 2 2 2 1.1 0 2-1 2-2 0-1.1-.9-2-2-2-1 0-2 1-2 2Zm-17.5 1.8c0 1 1 2 2 2 1.1 0 2-1 2-2 0-1.1-.9-2-2-2-1 0-2 1-2 2Z'
        />
      </mask>
      <g mask='url(#a)'>
        <path
          fill='#FC3'
          d='m13.6 22.5 33.8-3.7a5.4 5.4 0 1 1 1.2 10.8l-33.9 3.8c-3 .3-5.6-1.9-6-4.8-.2-3 1.9-5.7 4.9-6Zm33 1.4a2.2 2.2 0 1 0 4.4 0 2.2 2.2 0 1 0-4.5 0Zm-17 2.2a2 2 0 1 0 4 0 2 2 0 1 0-4 0Zm-17.5 1.8a2 2 0 1 0 4 0 2 2 0 1 0-4 0Z'
        />
        <path
          fill='url(#b)'
          fill-opacity='.8'
          fill-rule='evenodd'
          d='m50 31.8-9.3-14.1-2.6-.4 3.7 12.5c.5 2 3.2 2.6 8.2 2Z'
          clip-rule='evenodd'
          className={style.multiply}
        />
      </g>
      <path
        fill='#23015A'
        fill-rule='evenodd'
        d='M48.5 5.6c-1-2.8-4-4.4-6.8-3.4-2.9 1-4.4 4-3.5 6.8l13.4 41.5c.9 2.9 4 4.4 6.8 3.5 2.8-1 4.4-4 3.5-6.8L48.5 5.6Zm7.3 33c0 1.3-1 2.3-2.3 2.3-1.2 0-2.2-1-2.2-2.2 0-1.2 1-2.2 2.2-2.2 1.3 0 2.3 1 2.3 2.2ZM43.3 5.2c1 0 2 1 2 2 0 1.1-1 2-2 2s-2-.9-2-2c0-1 1-2 2-2ZM50.7 24c0 1-.9 2-2 2-1 0-2-1-2-2 0-1.1 1-2 2-2 1.1 0 2 .9 2 2Z'
        clip-rule='evenodd'
      />
      <mask
        id='c'
        width='57'
        height='22'
        x='2'
        y='33'
        maskUnits='userSpaceOnUse'
        className={style.alpha}
      >
        <path
          fill='#fff'
          d='M58.8 37.6c.6 2.9-1.3 5.8-4.2 6.4l-46 10c-2.9.7-5.8-1.1-6.4-4-.6-3 1.2-5.8 4.1-6.5l46-10c3-.7 5.8 1.1 6.5 4ZM5 48.7a2.2 2.2 0 1 0 4.4 0 2.2 2.2 0 1 0-4.4 0Zm24.6-5a2 2 0 1 0 3.9 0 2 2 0 1 0-4 0Zm21.9-5a2 2 0 1 0 4 0 2 2 0 1 0-4 0Z'
        />
      </mask>
      <g mask='url(#c)'>
        <path
          fill='#FF2346'
          d='M58.8 37.6c.6 2.9-1.3 5.8-4.2 6.4l-46 10c-2.9.7-5.8-1.1-6.4-4-.6-3 1.2-5.8 4.1-6.5l46-10c3-.7 5.8 1.2 6.5 4ZM5 48.7a2.2 2.2 0 1 0 4.4 0 2.2 2.2 0 1 0-4.4 0Zm24.6-5a2 2 0 1 0 3.9 0 2 2 0 1 0-4 0Zm21.9-5a2 2 0 1 0 4 0 2 2 0 1 0-4 0Z'
        />
        <path
          fill='url(#d)'
          fill-opacity='.7'
          fill-rule='evenodd'
          d='m8 56.3 5.6-15.4 5-1.4a597 597 0 0 0-3.8 14c-.4 1.7-2.7 2.7-6.8 2.8Z'
          clip-rule='evenodd'
          className={style.multiply}
        />
      </g>
      <path
        fill='#23015A'
        fill-rule='evenodd'
        d='M4.8 39.3A4.2 4.2 0 0 1 11 37l.1.1a3.3 3.3 0 0 1 4.5 4l-3 9.4c-1 2.9-4 4.4-6.8 3.5-2.9-1-4.4-4-3.5-6.9l2.5-7.8Zm.5 9.4c0 1 1 2 2 2s2-.9 2-2c0-1-1-2-2-2-1.1 0-2 1-2 2Z'
        clip-rule='evenodd'
      />
      <defs>
        <linearGradient
          id='b'
          x1='40.5'
          x2='43.3'
          y1='24.7'
          y2='23.9'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#D10000' stop-opacity='0' />
          <stop offset='1' stop-color='#910000' stop-opacity='.3' />
        </linearGradient>
        <linearGradient
          id='d'
          x1='15.4'
          x2='12.4'
          y1='49.1'
          y2='48.4'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#23015A' stop-opacity='0' />
          <stop offset='1' stop-color='#23015A' stop-opacity='.3' />
        </linearGradient>
      </defs>
    </svg>
  )
}
